import React from "react"
import SEO from "../components/seo"
import Layout from '../layouts/findep'
import DescargarKnox from '../components/DescargarKnox'


const DescargarKnoxPage = ({ pageContext }) => (
  <Layout companyName={pageContext.company}>
    <SEO title={pageContext.title} />
    <DescargarKnox pageContext={pageContext} />
  </Layout>
)

export default DescargarKnoxPage